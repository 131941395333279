/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

function Hero() {
  return (
    <div className="relative pt-10 pb-20 sm:py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div className="mx-auto max-w-2xl lg:max-w-4xl lg:px-12">
          <h1 className="font-display font-corsive text-2xl font-medium tracking-tighter sm:text-5xl">
            The Ledger Show
          </h1>
          <h1 className="mt-6 font-display font-corsive text-5xl font-bold">
            <span className="block">A weekly chat about finance</span>
            and finance operations.
          </h1>
          <h1 className="mt-6 font-display text-lg font-semibold tracking-tighter">
            hosted by: Christian Barra &#8226; founder{' '}
            <a
              href="https://www.tresorbase.com"
              className="underline"
              target="_blank"
              rel="noopener"
            >
              Tresorbase
            </a>
          </h1>
          <div className="mt-6 space-y-6 font-display text-2xl tracking-tight text-white-900">
            <p>
              What does it take to run a finance team or finance operations in
              2023?
            </p>
            <p>
              Every week I interview a special guest. And together we discover
              how they are tackling finance, finance operations, billing and
              payments to help and sustain company&apos;s growth.
            </p>
            <p>
              Tune in every week. And get insight and knowledge to help you do
              better and grow your career.
            </p>
            <p>
              Check the podcast on{' '}
              <a
                href="https://podcasts.apple.com/us/podcast/the-ledger-show/id1635223311"
                target="_blank"
                className="underline"
                rel="noopener"
              >
                Apple podcast
              </a>{' '}
              or{' '}
              <a
                className="underline"
                href="https://open.spotify.com/show/2TObGUOMl7yHJlmolshfH8"
                target="_blank"
                rel="noopener"
              >
                Spotify
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
