import React from 'react';
import Podcast from '../components/podcast';
import Layout from '../components/layout';
import Seo from '../components/seo';

function IndexPage({ location }) {
  return (
    <Layout>
      <Seo title="Podcast" path={location.pathname} />
      <Podcast />
    </Layout>
  );
}

export default IndexPage;
